/* Fonts */

@font-face {
  font-family: "Handmade";
  src: url("../fonts/handmade.woff2");
}

@font-face {
  font-family: "Grand-hotel";
  src: url("../fonts/grand-hotel.woff2");
}

@font-face {
  font-family: "Amatic";
  src: url("../fonts/amatic.woff2");
}


/* ScrollBar */

::-webkit-scrollbar {
  width: 10px;
  opacity: 0.8;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: #DDD1B5;
  opacity: 0.8;
}

::-webkit-scrollbar-thumb {
  z-index: 99;
  background-color: #6C4A3499;
  border-radius: 4px;
  opacity: 0.8;

}

::-webkit-scrollbar-thumb:hover {
  opacity: 1;
}

.active {
  font-weight: 900;
}

/* All */

body {
  position: relative;
  scroll-behavior: smooth;
  left: 2em;
  width: 90vw;
  height: 100vh;
  background: linear-gradient(#e8e4dc, #DDD1B5);
  background-attachment: fixed;
  overflow-x: hidden;
}

/* Header */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
}

.header-logo {
  height: 10em;
  margin: 1em;
  border-radius: 20em;
  border: #6C4A34 solid 3px;
  margin-left: 1em;
}

.header-links {
  display: flex;
  gap: 1em;
  margin-right: 1em;
}

.header-link {
  text-decoration: none;
  font-family: "Handmade";
  font-size: 1.5em;
  color: #A64A93;
  padding: 0.5em;
}

.header-link:hover {
  opacity: 0.7;
  text-decoration: underline;
}

/* Home Page */

.Home-news {
  position: relative;
  color: #6C4A34;
}

.Home-news-title {
  position: relative;
  font-family: "Grand-hotel";
  font-size: 2.6em;
}

.Home-news-subtitle {
  position: relative;
  font-family: "Grand-hotel";
  font-size: 2em;
  color: #BAABA0;
  margin-bottom: 1.5em;
  font-weight: 400;
}

.images {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 2em;
}

.image-container {
  position: relative;
  border: #bcb197 solid 4px;
  border-radius: 0.6em;
  transition: transform 0.4s;
}

.image {
  display: flex;
  left: 0;
  height: 20em;
  border-radius: 0.4em;
}

.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6em;
  background-color: #6C4A3499;

  padding: 1em;
  color: #DDD1B5;
  text-align: center;
  font-size: 1.3em;
  font-family: "Handmade";
  margin: 0;
  border-radius: 0 0 0.5em 0.5em;
}

.image-container:hover {
  transform: scale(1.2);
}

.about {
  position: relative;
  margin-top: 9em;
}

.about-section {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 90%;
}

.about-title {
  position: relative;
  width: 100%;
  display: inline-block;
  font-family: "Grand-hotel";
  font-size: 2.2em;
  margin-top: -1em;
  color: #A64A93;
}

.about-text {
  position: relative;
  background: #eee;
  font-size: 1.6em;
  background: #E3DBC9;
  color: #6C4A34;
  font-family: "Handmade";
  border: 2px solid #6C4A34;
  box-shadow: 8px 8px 12px #6C4A3499;
  border-radius: 10px;
  padding: 10px;
  margin: 0 auto;
}


.underline {
  text-decoration: underline;
}

.Home-frames {
  position: relative;
  color: #6C4A34;
  z-index: 0;
}

.Home-frames-title {
  position: relative;
  font-family: "Grand-hotel";
  color: #A64A93;
  margin-top: 2.2em;
  font-size: 2.2em;
}

.o-embed__user {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.o-insta {
  margin: 2em;
  width: 800px;
  height: 525px;
  border: 1px #6C4A34 solid;
}

.o-insta:hover,
.o-tiktok:hover {
  cursor: pointer;
}

.footer {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  bottom: 0;
}

.footer img {
  width: 1.6em;
  margin: 2em;
}

.footer .description {
  display: inline-block;
  font-family: "Amatic";
  text-align: center;
  text-decoration: none;
  padding: 0.6em;
  font-size: 1.2em;
  color: #A64A93;
}

.description:hover,
.instagram:hover,
.whatsapp:hover,
.mail:hover {
  opacity: 0.8;
}

/* home in responsive */

@media (max-width: 1420px) {
  .header-link {
    left: 28em;
  }
}

@media (max-width: 1280px) {
  .header-link {
    left: 22em;
  }

  .image {
    height: 18em;
  }
}

@media (max-width: 1140px) {
  .header-link {
    left: 18em;
  }

  .image {
    height: 15em;
  }

  .image-overlay {
    font-size: 1em;
  }
}

@media (max-width: 1040px) {
  .header-link {
    left: 16em;
    padding: 1em;

  }
}

@media (max-width: 950px) {
  .header-logo {
    height: 8em;
  }

  .header-link {
    left: 14em;
    padding: 0.8em;
  }

  .image {
    height: 13em;
  }

  .image-overlay {
    font-size: 0.8em;
  }

  .about-text {
    font-size: 1.3em;
  }
}

@media (max-width: 835px) {
  .header-logo {
    height: 6em;
  }

  .header-link {
    left: 10em;
    font-size: 1.2em;

  }

  .Home-news-title {
    font-size: 2em;
  }

  .Home-news-subtitle {
    font-size: 1.4em;
  }

  .images {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .image-container {
    position: relative;
    border: #bcb197 solid 4px;
    border-radius: 0.6em;
    transition: transform 0.4s;
    margin-bottom: 2.4em;
    margin-top: 1em;
    width: 20em;
    height: 20em;
  }

  .image {
    width: 19.5em;
    height: 19.5em;
  }

  .image-overlay {
    font-size: 1.2em;
  }

  .about-title {
    font-size: 2em;
  }

  .Home-frames-title {
    font-size: 2em;
  }

  .o-insta,
  .o-tiktok {
    width: 400px;
    height: 370px;
  }

  .footer .description {
    font-size: 0.8em;
  }
}

@media (max-width: 636px) {
  .header-logo {
    height: 5em;
  }

  .header-link {
    left: 11em;
    font-size: 0.8em;
  }

  .Home-news-title {
    font-size: 2.5em;
  }

  .Home-news-subtitle {
    font-size: 1.7em;
  }

  .image-container {
    width: 18em;
    height: 18em;
  }

  .image {
    width: 17.5em;
    height: 17.5em;
  }

  .image-overlay {
    font-size: 1em;
  }
}

@media (max-width: 560px) {

  body {
    padding: 0;
  }

  .o-insta,
  .o-tiktok {
    width: 370px;
    height: 360px;
  }

  .footer img {
    width: 1em;
    margin: 1em;
  }

  .footer .description {
    font-size: 0.6em;
  }
}

@media (max-width: 450px) {

  .header-logo {
    height: 4em;
    margin-left: -0.8em;
    border: #6C4A34 solid 1px;
  }

  .header-link {
    left: 7em;
    font-size: 0.8em;
  }

  .Home-news-title {
    left: -0.8em;
    font-size: 2em;
  }

  .Home-news-subtitle {
    left: -0.8em;
    font-size: 1.5em;
  }

  .about-title,
  .Home-frames-title {
    font-size: 1.6em;
  }

  .images {
    position: relative;
    display: flex;
    margin-left: -2em;

  }

  .about-title {
    left: -0.8em;
  }

  .Home-frames-title {
    font-size: 1.6em;
    left: -0.8em;
  }

  .o-insta {
    width: 260px;
    height: 340px;
    margin-left: -2em;

  }

  .Home-frames {
    position: relative;
    justify-content: left;
  }

  .footer {
    margin-left: -2em;

  }
}

@media (max-width: 350px) {

  .header-link {
    left: 5em;
    font-size: 0.8em;
  }

  .Home-news-title {
    left: -0.8em;
    font-size: 1.9em;
  }

  .Home-news-subtitle {
    left: -0.8em;
    font-size: 1.3em;
  }

  .images {
    margin-left: -0.8em;
  }

  .o-insta,
  .o-tiktok {
    margin-left: -0.8em;

  }

  .image-container {
    margin: 4em;
  }

  .about-title {
    left: -0.8em;
    font-size: 1.5em;
  }

  .about-text {
    font-size: 1em;
    left: -0.5em;

  }

  .Home-frames-title {
    font-size: 1.5em;
    left: -0.8em;
  }

  .Home-frames {
    position: relative;
    justify-content: left;
  }
}