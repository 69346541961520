/* Fonts */

@font-face {
    font-family: "Handmade";
    src: url("../fonts/handmade.woff2");
}

@font-face {
    font-family: "Grand-hotel";
    src: url("../fonts/grand-hotel.woff2");
}

@font-face {
    font-family: "Amatic";
    src: url("../fonts/amatic.woff2");
}

/* ScrollBar */

::-webkit-scrollbar {
    width: 18px;
}

::-webkit-scrollbar-track {
    background: #DDD1B5;
}

::-webkit-scrollbar-thumb {
    z-index: 99;
    background-color: #6C4A3499;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    opacity: 0.7;
}

.active {
    font-weight: 900;
}

/* All */

body {
    position: relative;
    scroll-behavior: smooth;
    left: 2em;
    width: 90vw;
    height: 100vh;
    background: #e8e4dc;
    background-attachment: fixed;
}

/* Header */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em;
}

.header-logo {
    height: 10em;
    margin: 1em;
    border-radius: 20em;
    border: #6C4A34 solid 3px;
    margin-left: 1em;
}

.header-links {
    display: flex;
    gap: 1em;
    margin-right: 1em;
}

.header-link {
    text-decoration: none;
    font-family: "Handmade";
    font-size: 1.5em;
    color: #A64A93;
    padding: 0.5em;
}

.header-link:hover {
    opacity: 0.7;
    text-decoration: underline;
}

/* Recipes Page */

.title-recipes {
    position: relative;
    margin-top: 0.5em;
    font-size: 2em;
    left: 1em;
    font-family: "Grand-hotel";
    color: #D788C3;
    text-decoration: #D788C3 underline;
}

.my-recipes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 95vw;
    margin-top: 2em;
}

.popin-opener {
    display: inline-block;
    text-align: center;
    background-color: #DDD1B5;
    padding: 1.2em;
    margin: 0.5em;
    width: 10em;
    border-radius: 1em;
    border: 3px solid #6C4A34;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 1.8em;
    font-family: "Handmade";
    color: #6C4A34;
}

.recipes-card {
    font-family: "Handmade";
    font-size: 0.8em;

}

.popin-opener:hover {
    text-decoration: wavy underline #6C4A34;
    cursor: pointer;
}

.popin {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 60vw;
    height: 90vh;
    padding: 20px 40px;
    background: #DDD1B5;
    border: 1px solid #6C4A34;
    box-sizing: border-box;
    border-radius: 1em;
    transform: translate(-50%, -50%);
}

.popin-title {
    position: absolute;
    display: inline;
    top: -1em;
    text-decoration: underline;
    color: #D788C3;
    font-size: 1.4em;

}

.popin-content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3em;
    top: 55%;
    left: 50%;
    width: 60vw;
    transform: translate(-50%, -50%);
}

.popin-ingredients,
.popin-procedure {
    position: relative;
    padding: 2em;
    text-align: left;
}

.popin-ingredients {
    border-right: 2px solid #6C4A34;
    /* width: 7em; */
}

.popin .close {
    position: fixed;
    top: -1em;
    right: -1em;
    width: 2em;
    height: 2em;
    font-family: Arial, Helvetica, sans-serif;
    background: #D788C3;
    border-radius: 50%;
    border: 1px solid #DDD1B5;
    color: #DDD1B5;
    cursor: pointer;
}


/* Recipes page in responsive */

@media (max-width: 1050px) {

    .popin-content {
        font-size: 1.2em;

    }

    .popin .close {
        right: -0.5em;
    }
}

@media (max-width: 670px) {
    .my-recipes {
        margin-left: -2em;
    }

    .popin-content {
        font-size: 1.1em;
    }
}

@media (max-width: 524px) {

    .scr .my-recipes {
        margin-left: -2em;
    }

    .popin-content {
        font-size: 1em;
    }
}


@media (max-width: 470px) {

    .content-recipes {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .title-recipes {
        font-size: 1.6em;
    }

    .my-recipes {
        margin: 0;
    }

    .popin-opener {
        margin-left: -1.5em;
        width: 15em;
    }

    .popin .close {
        /*  */
        right: -3em;

    }

    .recipes-card {
        font-size: 0.6em;
    }

    .popin {
        height: 70vh;
        width: 70vw;
        margin-left: -2em;
    }
}

@media (max-width: 386px) {
    .title-recipes {
        font-size: 1.4em;
    }

    .popin-opener {
        font-size: 1.6em;
        width: 15em;
    }

    .popin-content {
        font-size: 0.8em;
    }
}